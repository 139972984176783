<template>
  <div class="main">
    <div class="filter">
      <div class="item cl">
        <div class="label">{{$t('ProductNumber')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" v-model="filterData.productCode" :placeholder="$t('PleaseEnter')"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('ProductName')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" v-model="filterData.title" :placeholder="$t('PleaseEnter')"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Departure')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" v-model="filterData.departureCity" :placeholder="$t('PleaseEnter')"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('PostStatus')}}：</div>
        <div class="fl">
          <el-select class="select" clearable v-model="filterData.isStatus" size="small" :placeholder="$t('All')">
            <el-option v-for="item in statusOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <button class="button button-primary small m-r" type="button" @click="getList()">{{$t('search')}}</button>
      <button class="button small" type="button" @click="clearFilterData">{{$t('Clear')}}</button>
    </div>
    <div class="txt-r">
      <button class="button m-r" type="button" v-on:click="deleteAll" v-if="false">{{$t('BatchDeletion')}}</button><!--暂时隐藏-->
      <button v-if="adminUserId === 'd61ca9bfd34d47129cdee1f426119b47'" class="button button-primary" type="button" @click="$router.push({name: 'HotProductSelect'})">{{$t('Edit Hot Product')}}</button>
      <button class="button button-success"
              type="button"
              @click="$router.push({name:'AdminProductAdd'})">{{$t('New')}}
      </button>
    </div>
    <div class="table border">
      <table>
        <thead>
        <tr>
          <th><input type="checkbox" id="All" v-model="all" value="" class="checkbox-input" v-on:click="optionAll"/></th>
          <th>{{$t('ProductNumber')}}</th>
          <th width="220">{{$t('ProductName')}}</th>
          <th>{{$t('Departure')}}</th>
          <th>{{$t('PostStatus')}}</th>
          <th>{{$t('Classification')}}</th>
          <th>{{$t('ReleaseTime')}}</th>
          <th>{{$t('DataStatus')}}</th>
          <th>{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) of list" :key="item.productId">
<!--          <td class="checkbox"><img src="../../../assets/images/checkbox.png"/></td>&lt;!&ndash;有毒，用图片来代表复选框&ndash;&gt;-->
          <td><input type="checkbox" v-bind:id="index+1" v-bind:value="item.productId" class="checkbox-input" v-model="ids"/></td>
          <td>{{item.productCode}}</td>
          <td>{{item.title}}</td>
          <td>{{item.departureCity}}</td>
          <td class="nowrap">
            <el-dropdown @command="changeStatus">
              <a href="javascript:;">
                {{item.isStatus|productStatus}} <i class="el-icon-caret-bottom"></i>
              </a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{index,productId:item.productId,status:'published'}"
                                  v-if="['unpublished','obtained'].includes(item.isStatus)">{{$t('Release')}}
                </el-dropdown-item>
                <el-dropdown-item :command="{index,productId:item.productId,status:'obtained'}"
                                  v-if="item.isStatus==='published'">{{$t('Obtained')}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </td>
          <td v-html="(item.classifications||'').replace(/,/g,'<br/>')"></td>
          <td class="nowrap">{{item.releaseDate|formatDate('yyyy.MM.dd')}}</td>
          <td class="nowrap">
            <!--<span class="link">{{$t('Route')}}</span>-->
            <!--<span class="gap-line">｜</span>-->
            <span class="link" @click="toEditPrice(item.productId)">{{$t('Price')}}</span>
          </td>
          <td class="nowrap">
            <el-dropdown class="btn-edit" trigger="click" @command="toEdit">
              <div><img class="vam" src="../../../assets/images/icon/pen.png"/></div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="lang of languageOption"
                                  :command="{language:lang.value,productId:item.productId}"
                                  :key="lang.value">
                  {{lang.name}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!--<a class="btn-item" style="margin-right:8px;" href="javascript:;">
              <img src="../../../assets/images/icon/review.png"/>
            </a>-->
            <a v-if="false" class="btn-item" href="javascript:;" @click="deleteProduct(index,item.productId)">
              <img src="../../../assets/images/icon/ashcan.png"/>
            </a><!--暂时隐藏-->
          </td>
        </tr>
        </tbody>
      </table>
      <empty v-if="isEmpty" :title="$t('NoData')"/>
      <el-pagination class="pages txt-r"
                     layout="prev, pager, next"
                     :page-count="totalPages"
                     @current-change="changePage"
                     hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import order from '../../../mixins/order';
  import Empty from '../../../components/Empty';
  import {languageOptions} from '../../../utils/language';
  import {getProductList, deleteProduct, updateProductStatus, getProductLine} from '../../../api/admin-product';
  import {mapGetters} from 'vuex';
  // import Checkbox1 from '../../../components/Checkbox1';

  export default {
    name: 'AdminProductList',
    components: {Empty},
    mixins: [order],
    computed: {...mapGetters(['adminUserId'])},
    data() {
      return {
        filterData: {
          productCode: '',
          title: '',
          departureCity: '',
          isStatus: '',
          page: 1,
          perPage: 10
        },
        statusOptions: [
          {value: 'unpublished', label: this.$t('Unpublished')},
          {value: 'published', label: this.$t('Published')},
          {value: 'obtained', label: this.$t('Obtained')}
        ],
        status: '',
        list: [],
        totalPages: 1,
        isEmpty: false,
        languageOption: languageOptions,
        ids: [], // 存放产品id
        all: false, // 是否全选
        idsArray: []
      };
    },
    created() {
      window.sessionStorage.removeItem('productId');
      this.getList();
    },
    watch: {
      'ids': function() {
        if (this.ids.length === this.list.length) {
          this.all = true;
        } else {
          this.all = false;
        }
      }
    },
    methods: {
      // 获取产品列表
      getList(page = 1) {
        this.filterData.page = page;
        const data = this.filterData;
        getProductList(data).then(res => {
          const {value} = res;
          const list = value.list || [];
          console.log(list);
          this.list = list;
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
        });
      },
      changePage(page) {
        this.getList(page);
      },
      optionAll() {
       if (this.all === false) {
         for (var i = 0; i < this.list.length; i++) {
           this.ids.push(this.list[i].productId);
         }
         // this.ids = this.idsArray;
       } else {
         this.ids = [];
       }
      },
      deleteAll() {
          alert(this.ids);
      },
      // 修改发布状态
      changeStatus(command, instance) {
        const {index, productId, status} = command;
        const msg = {published: this.$t('Release'), obtained: this.$t('Obtained')}[status];
        this.$msgbox({
          title: this.$t('Prompt'),
          message: `確定要${msg}嗎？`,
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              updateProductStatus(productId, status).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.list[index].isStatus = status;
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      // 删除消息
      deleteProduct(index, productId) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              deleteProduct(productId).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.$message.success('刪除成功!');
                this.list.splice(index, 1);
                if (!this.list.length) {
                  this.getList();
                }
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(() => {
        }).catch(() => {
        });
      },
      toEdit(command) {
        this.$router.push({
          name: 'AdminProductAdd',
          query: {id: command.productId, language: command.language}
        });
      },
      // 直接跳转到编辑价格页面
      toEditPrice(productId) {
        const language = this.$i18n.locale;
        const loading = this.$loading({text: 'Loading'});

        // 获取产品路线
        getProductLine(language, productId).then(res => {
          loading.close();

          // 当前产品已有路线
          if (res.value && res.value.length) {
            this.$router.push({
              name: 'AdminProductAdd',
              query: {id: productId, language, activeName: 'PriceDate'}
            });
          } else {
            this.$msgbox({
              title: this.$t('Prompt'),
              message: this.$t('confirm.NewRoute'),
              center: true,
              showClose: false,
              showCancelButton: true,
              lockScroll: false
            }).then(action => {
              this.$router.push({
                name: 'AdminProductAdd',
                query: {id: productId, language, activeName: 'Route'}
              });
            }).catch(() => {
            });
          }
        }).catch(() => {
          loading.close();
        });
      },
      // 清除参数
      clearFilterData() {
        this.filterData = {
          productCode: '',
          title: '',
          departureCity: '',
          isStatus: '',
          page: 1,
          perPage: 10
        };
        this.getList();
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .table{
      .link{color:#1890ff;cursor:pointer;}
    }
    .gap-line{color:#e7e7e7;}
    .pages{padding-left:0;padding-right:0;}
    .btn-edit{margin-right:8px;vertical-align:middle;cursor:pointer;}
  }
  .filter{
    .item{
      display:inline-block;margin-right:24px;margin-bottom:24px;vertical-align:top;
      .label{float:left;line-height:32px;}
    }
    .ipt{
      width:120px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    /deep/ .select{width:120px;vertical-align:top;}
  }
  .checkbox-input{
    background-color: initial;
    cursor: default;
    appearance: checkbox;
    box-sizing: border-box;
    padding: initial;
    border: initial;
  }
</style>
